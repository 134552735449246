.industries-div{
    font-family: "Instrument Sans";
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    height: fit-content;
   
    margin: 0 auto;
    padding: 40px 35px;
    transition: 0.3s ease-in-out;
}


.industries-div  .d_flex{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.industries-div .menu__cta__wrapper{
display: flex;
flex-direction: column;
justify-content: space-between;
}
.industries-div .menu__cta__wrapper .lg_text_wrapper span{
    font-size: 38px;
    font-weight: 600;

}
.industries-div .menu__cta__wrapper .industry_left_img a{
    outline: none;

    img{
        border-radius: 15px;
        width: 100%;
        max-width: 350px;
        max-height: 120px;
    }
}
.industries-div .menu__cta__wrapper .industry_left_img .imag_panel{
    margin: 25px 0;
}

.industries-div .menu__cta__wrapper .industry_left_img .bottom-text .cirl_arw{
    border: 2px solid #707070;
    min-width: 38px;
    height: 38px;
    margin-left: 40px;
    display: flex
;
    border-radius: 50%;
    position: relative;
    align-items: center;
    justify-content: center;
}


.industries-div .menu__cta__wrapper .industry_left_img .bottom-text{
    display: flex;
    max-width: 400px;
}


.industries-div .para{
    font-size: 15px;
    line-height: 1.4;
}

.menu__col .cl-inner{
    display: flex;
    gap: 10px;
    padding: 0 0 20px;
    align-items: center;
}

.menu__col .cl-inner .left_icon_panel figure {
    background: #707070;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex
;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.right_text_panel .heading4{
    font-size: 15px;
    line-height: 1.2;
    display: inherit;
    color: #000;
   >a{ text-decoration: none;
color:inherit;
cursor: unset;}

}
@media(min-width:900px){
    .industries-div{
        background-color: white;
        position: fixed;
        left: 50%;
        transform: translate(-50%);
        top: 70px;
        display: none;
        width: 90%;
       
    } 
}

@media (max-width:900px){

    .industries-div{
        box-shadow: none;
        width:100%;
        padding: 8px;
        display: block;
        overflow: scroll;
        max-height: 360px;
    }
   .industries-div .d_flex{display: flex;
    flex-direction: column-reverse;
  
    

    }
    .menu__col .cl-inner .left_icon_panel figure {
     
        width: 20px;
        height: 20px;
   img{
    width:10px
   }
    }
    .right_text_panel .heading4 {
        font-size: 12px;
    }
    .menu__col .cl-inner {
        padding: 0 0 8px;
    }
    .industries-div .menu__cta__wrapper .lg_text_wrapper span {
        font-size: 24px;
        
    }
    .industries-div .menu__cta__wrapper {
        margin-top: 10px;
    }
    .industries-div .para {
        font-size: 12px;
    }
    .industries-div .menu__cta__wrapper .industry_left_img .bottom-text .cirl_arw {
       min-width: 20px;
       height: 20px; 
    }
    .industries-div .menu__cta__wrapper .industry_left_img .bottom-text{
        font-size: 12px;
    }


}