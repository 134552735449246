/* 

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
   */

#sliderr{
    font-family: 'Geist',sans-serif;
    background-color: #fff;
    border-radius: 1.5rem;
    outline: none;
    border: none; 
     box-shadow: 0 0.125rem 0.5rem -0.125rem rgba(24, 24, 24, 0.08), 0 0.5rem 0.75rem -0.125rem rgba(24, 24, 24, 0.16); 
}

#sliderr .sliderTxt{
    letter-spacing: -0.004em;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #032D60;
    margin-top: 12px;
}

#sliderr .sliderName{
    font-size: 1.125rem;
    line-height: 1.2;
    color: #032D60;
    letter-spacing: -0.004em;
    font-weight: 600;
    margin-bottom: 0;
}

#sliderr .sliderDesignation{
    font-size: 1.125rem;
    line-height: 1.2;
    color: #000;
    letter-spacing: -0.004em;
    font-weight: 400;
    margin-top: 6px;
    margin-bottom: 0;
}

#sliderr .SliderImgDiv{
    clip-path: circle(50% at 50% 50%);
    height:350px;
    width:350px;
    @media (max-width:500px) {
        height:250px;
        width:250px;
    }
}

#sliderr .SliderImgDivContainer{
    padding: 1rem 2rem;
}
#sliderr .SliderInfoDivContainer{
    padding: 1rem 2rem;
    text-align: left;
    @media (max-width:500px) {
        padding: 1rem 1.25rem;
    }
}

#sliderr .sliderBoxContainer{
    border-radius: 36px;
    /* border: 1px solid rgba(24, 24, 24, 0.04);
    box-shadow: 0 0.125rem 0.5rem -0.125rem rgba(24, 24, 24, 0.08), 0 0.5rem 0.75rem -0.125rem rgba(24, 24, 24, 0.16); */
}
#sliderr .sliderBox{
    display: flex;
    align-items: center;
    /* border-radius: 24px; */
    padding: 1rem 2rem;
    gap: 2rem;
     /* border: 1px solid rgba(24, 24, 24, 0.04);  */
     /* box-shadow: 0 0.125rem 0.5rem -0.125rem rgba(24, 24, 24, 0.08), 0 0.5rem 0.75rem -0.125rem rgba(24, 24, 24, 0.16);  */
     box-shadow: none;
     border:none;
    @media (max-width:500px) {
        flex-direction: column;
        padding: 1rem 1.25rem;
        gap: 0;
    }
    }

.swiper-button-next:after, 
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
   
    font-size: 24px;
    font-weight: 900;
    color: #000;
   
    border-radius: 50%;
     /* border: 1px solid rgba(24, 24, 24, 0.04);
    box-shadow: 0 0.125rem 0.5rem -0.125rem rgba(24, 24, 24, 0.08), 0 0.5rem 0.75rem -0.125rem rgba(24, 24, 24, 0.16); */
    text-align: center;
    line-height: 1;
    display: inline-block; 
    
}


    .swiper-button-next, .swiper-rtl .swiper-button-prev, .swiper-button-prev, .swiper-rtl .swiper-button-next{
            border-radius: 50%;
             border: 1px solid rgba(24, 24, 24, 0.04);
    box-shadow: 0 0.125rem 0.5rem -0.125rem rgba(24, 24, 24, 0.08), 0 0.5rem 0.75rem -0.125rem rgba(24, 24, 24, 0.16);
    height: 50px;
    width: 50px;
    border: 1px solid #fff;
        background-color: #fff;
    }