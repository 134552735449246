.job-description{
    padding-top: 75px;
    font-family: Instrument Sans, sans-serif;
    
display: flex;
max-width: 80%;
margin: 0 auto;





}



.job-description .left{
width:55%;
padding: 30px 50px 30px 0;

.image{
    margin-bottom: 20px;
    max-width:160px;
    img{
        width:100%;
    }
}

.main-title{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    h1,p{
        margin: 0;
    }
}

.job-title{
    font-size: 24px;
    font-weight: 600;
    color: #000000;
  
}

.jobLocation {
    margin-bottom: 10px;
    span{
        margin: 0 5px;
    }
    i{
        margin-left: 5px;
    }
}

.job-type{
    font-size: 14px;
    font-weight: 400; 
    p{color:#5e595e;
        margin: 0;
    }
    .location{
        color: #383c38;
    }
}

.job-section{
    color:#444545;
    .title{
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 5px;
        margin-top: 30px;
                
    }
    .content{
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;

        ul{list-style: disc;
            padding-left: 20px;
        }
    }
}
.job-apply{
    border-top: 1px solid #ddd;
padding: 30px 0;
margin-top: 30px;
font-size: 16px;

.intrested,.privacy{
    margin: 0 auto;
    background-color: #3973E6 ;
    color: #FFFFFF ;
    border: 0 ;
    padding-top: 8px ;
    padding-bottom: 8px ;
    line-height: 1.2;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
    border-radius: 3px;
    max-width: 260px;
    a{color: #FFFFFF ;
        text-decoration: none;
    }
}
.privacy{
    background: transparent;
    color:#3973E6;
    margin-top:10px
}

}

}



.job-description .right{
    padding: 50px 40px;
    width:45%;
    background: #fafafa;

    .refer-btn{
       button{max-width: 400px;
border-radius: 4px;
border: none;
outline: none;
padding: 8px;
font-size: 16px;
display: block;
margin-bottom: 15px;
width: 100%;
cursor: pointer;
font-family: Instrument Sans;


       }
       .intrest{
        background-color: #3973E6 ;
    color: #FFFFFF ;
    
       }
       .refer{background: transparent;
        border: 1px solid #ddd;
        transition: 0.3s ease-in-out;
        &:hover{
            background-color: #0070CD;
            border-color: #00549a;
            color: #fff;
        }
       }
    }

    .share{

        .social{margin-bottom: 40px;
            display: flex;
            gap: 15px;
            font-size: 20px;
            margin-left: 5px;
            color:#444444;
            svg{
                cursor: pointer;
            }
        }
    }

    .other-job{
margin-top: 30px;
        .job{
margin-bottom: 15px;
            h6{cursor: pointer;
                color: #272525;
           font-size: 16px;
           font-weight: 400; 
           margin: 0;
           
            }
            p{
margin-top: 1px;
font-size: 14px;
color: #5e595e;
            }
           
        }

        a{
            font-size: 16px;
            color: #3973e6;
            text-decoration: none;
            font-weight: 500;
/* border: 1px solid #3973e6; */
/* padding: 8px 30px; */
border-radius: 6px;
margin-top: 20px;
        }
    }

    }



    @media (max-width:768px){

        .job-description{
            flex-direction: column;
            max-width: 100%;

            .left{
                margin: 0 auto;
                width: 90%;
                 padding: 20px 0;
            }
            .right{
                width: 100%;
            }
        }
    }




    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        flex-direction: column;
      }
      
      .error-container {
        text-align: center;
        padding: 20px;
      }