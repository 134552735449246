.circular-loader {
    /* border: 4px solid #f3f3f3; Border color */
    border-top: 4px solid #3498db; 
    border-radius: 50%; /* Make it circular */
    
    animation: spin 1s linear infinite; /* Apply the spinning animation */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg); /* Starting position */
    }
    100% {
      transform: rotate(360deg); /* Ending position */
    }
  }
  