.App {
  text-align: center;
}

/* html  body  .comp1{
  font-size: 80%;
} */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.arro {
  position: absolute;
  border-radius: 3px;
  font-size: 70px;
  top: 40%;
  color: white;
}
/* .arrow_left {
  position: absolute;
  border-radius: 3px;
  font-size: 30px;
  right: 0%;
  top: 40%;
  color: #000;
}
.arrow-right {
  position: absolute;
  border-radius: 3px;
  font-size: 30px;
  left: 0%;
  top: 40%;
  color: #000;
} */

/* .arrow:hover {
  background: #869791;
  color: #fff;
} */

.next {
  right: 20px;
}

.prev {
  left: 20px;
}
.owl-theme .owl-nav{
  margin-top: -20px !important;
}

