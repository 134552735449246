#jobBox{
    color: #032d60;
    font-family: 'Geist',sans-serif;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: -0.004em;
    text-align: left;
    @media (max-width:500px){
        flex-direction: column;
        gap: 2rem;
        margin-inline: 3px;
    }
}

#jobBox .headingJob h2{
    font-size: 48px;
    margin-bottom: 24px;
    margin-top: 0;
    @media (max-width:500px){
        font-size: 40px;
        text-align: center;
    }
}
#jobBox .descriptionJob{
    margin-bottom: 24px;
    width: 85%;
    @media (max-width:500px){
        width: 100%;
    }
}
#jobBox .descriptionJob p{
    font-size: 16px;
    font-family: Instrument Sans;
    margin-bottom: 0;
    font-weight: 300;
    margin-top: 0;
    color: #181818;
    @media (max-width:500px){
        /* text-align: center; */
    }
}
#jobBox .buttonJob{
    margin-top: 2rem;
    @media (max-width:500px){
        text-align: center;
    }
}
#jobBox .buttonJob a{
    padding: 10px 24px;
    margin: 24px 24px 0 0;
    background: linear-gradient(90deg, rgb(10, 68, 255) -17.74%, rgb(174, 15, 255) 138.18%);
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    border-radius: 2px;
    text-decoration: none;
    @media (max-width:500px){
        margin: 24px 0 0 0;
    }
}
#jobBox .jobBoxL{
    display: flex;
    justify-content: left;
}

#jobBox .jobBoxLcontainer{
    @media (min-width:768px){
        width:100%;
        max-width: 600px;
    }
}

#jobBox .jobBoxList{
    background-color: #fff;
    padding: 12px 0;
    position: relative;
    cursor: pointer;
}
#jobBox .jobTag{
    font-size: 0.75rem;
    font-weight: 700;
    border-radius: 2.5rem;
    width: max-content;
    padding: 0.125rem 0.75rem;
    background-color: #EAF5FE;
    /* float: left; */
}
#jobBox .jobListBoxContainer{
    text-align: left !important;
    padding: 0 20px;
    margin-bottom: 2rem;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 0.125rem rgba(24, 24, 24, 0.08), 0 0.125rem 0.25rem 0.0625rem rgba(24, 24, 24, 0.16);
    @media (max-width:500px){
        padding: 0 12px;
    }
}
#jobBox .jobTitle{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    /* padding-top: 12px; */
}
#jobBox .jobLocation{
    display: inline-flex;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 1rem;
    > i{
        margin-left: 5px;
        font-size: 14px;;
    }
    >span{
        margin: 0 5px;
    }
}
#jobBox .bookmark{
    position: absolute;
    top: 15px;
    right: 0;
}

#jobBox .bookmark i{
    font-size: 20px;
}
#jobBox .jobBoxR{
    overflow-y: scroll;
    max-height: 430px;
    padding: 0 2px;
   
    max-width: 600px;


    .jobsImage{
        border-radius: 10px;
        overflow: hidden;
        @media (max-width:992px){
            max-width:500px;
        }
    }
    @media (max-width:500px){
        width: 100%;
    }
   
    @media (min-width:992px){
        width:75%;
    }
    @media (min-width:1300px){
        width: 100%;
    }
}
#jobBox .jobBoxR::-webkit-scrollbar{
    width: 6px;
    height: 8px;
}

#jobBox .jobBoxR::-webkit-scrollbar-track {
    background-color: #F1F7F5;
    border-radius: 10px;
  }
 
  #jobBox .jobBoxR::-webkit-scrollbar-thumb {
    background-color: #e5e9e8f5;
    border-radius: 10px;
    /* border: 2px solid #f1f1f1; */
  }
  
 
  #jobBox .jobBoxR::-webkit-scrollbar-thumb:hover {
    background-color: #7c7c7c;
  }
  
  
  #jobBox .jobBoxR::-webkit-scrollbar-corner {
    background-color: #f1f1f1;
  }


/* trial */
#heroSection{
    display: flex;
    flex-direction: row-reverse;
    width: 100vw;
    overflow: hidden;
    align-items: center;
    font-family: 'Geist',sans-serif;
    background-image: linear-gradient(to bottom, #c6e9ff 0%, #fff 75%);
}
#heroSection .heroR{
    width: 60%;
    padding-bottom: 3rem;
}
#heroSection .heroL{
    width: 50%;;
}
#heroSection .heroLcontent{
    width: 75%;
    float: right;
    padding: 64px 32px 64px 0;
}
#heroSection .heroR img{
    float: right;
    position: relative;
    right: -40px;
    width: 100%;
}
#heroSection .heroTitle{
    margin-bottom: 1.5rem;
    color: #032d60;
}
#heroSection .heroTitle h4{
    font-size: 4rem;
    line-height: 1.3;
    letter-spacing: -0.018em;
    margin: 0;

}
#heroSection .heroBtns{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}
#heroSection .viewBtn{
    margin-right: 2rem;
}
#heroSection .heroR img{
    clip-path: circle(59% at 50% 24%);
}

#heroSection .viewBtn, #heroSection .joinBtn{
    padding: 13px 32px;
    text-align: center;
    border: 1px solid #0176d3;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 600;
    margin-top: 1rem;
}
#heroSection .viewBtn{
    cursor: pointer;
    color: #fff;
    background: linear-gradient(90deg, rgb(10, 68, 255) -17.74%, rgb(174, 15, 255) 138.18%);
    /* border: 1px solid rgba(255, 255, 255, 0); */
}
#heroSection .joinBtn{
    background: linear-gradient(90deg, rgb(10, 68, 255) -17.74%, rgb(174, 15, 255) 138.18%);
    background-color: #fff;
}



@media (max-width:992px){

    #heroSection .heroTitle h4{
font-size: 3rem;
    }
    #jobBox{
        max-width: 85%;
        margin: 0 auto;
    }
}

@media (max-width:767px){
    #heroSection {
flex-direction: column;

    }
    #heroSection .heroR {
        width: 100%;
        padding: 0;
    }
    #heroSection .heroLcontent {
        width: 100%;
        
    }
    #heroSection .heroL {
        width: 80%;
    }
    #jobBox{
       flex-direction: column;
       gap:50px;
    }
    #jobBox .descriptionJob {
        width:100%;
    }
    #jobBox .jobBoxR {
        width:90%;
    }
    
}
@media screen and (max-width:500px) {
    #heroSection{
        flex-direction: column;
    }
    #heroSection .heroR,#heroSection .heroL{
        width: 100%;
        padding-bottom: 0rem;
    }
    #heroSection .heroR img{
        right: 0;
        width: 105%;
    }
    #heroSection .heroR{
        display: flex;
        justify-content: center;
    }
    #heroSection .heroL {
        width:90%
    }
    #heroSection .heroLcontent{
        width: 100%;
        padding: 0;
        padding-top: 20px;
        text-align: center;
    }
    #heroSection .heroTitle h4{
        font-size: 2.5rem;
    }
    #heroSection .viewBtn{
        margin-right: 0;
    }
    #heroSection .viewBtn,#heroSection .joinBtn{
        margin: 0 auto;
        width: 70%;
        margin-bottom: 30px;
    }
    #jobBox {
        
        max-width:100%;
        width:100%;
    }
    #jobBox .jobBoxR {
        width:100%;
    }
   
}
