
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.services{opacity: 0;
    border-radius: 0px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: none;
    position: fixed;
    top: 70px;
    left: 50%;
    transform: translate(-50%);
    border:none;
    width:90%;
    height:fit-content;
    margin:auto;
    background-color: white;
   
}
.services .section{
    padding:30px;
    display:flex;
    justify-content: space-around;
}
.services h1{
    font-size: 38px;
    font-weight: 600;
    font-family: "Instrument Sans", sans-serif;
}
.services h4{
    font-size: 16px;
    font-weight: 600;
    font-family: "Instrument Sans", sans-serif;
    text-transform: uppercase;
}
.services p{
    font-size: 14px;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
}
.services h1 span{
    color:blue;
}
.services img{
    margin:50px 0;
    margin-bottom: 90px;
}
.services .section .sec{
    width:20%;
}
.services .section .sec1{
    width:30%;
}
.services .section .secc{
    margin:20px 5px;
    margin-bottom: 25px;
    border-bottom: 1px solid rgb(218, 215, 215);
}
.services .section .bottom{
    border: none;
}
/* .services .sec1-2{
    margin-top:100px;
} */

.services .h5{
    font-size: 15px;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
}



@media screen and (max-width: 1500px) {
    .services {
        height: auto;
        width: 95%;
        padding:20px;
    }
    .services h1{
        font-size: 1rem;
    }
    .services p{
        font-size: 14px;
    }
    .services .section {
        padding: 20px;
    }
    
    .services .section .sec {
        width: 22%;
    }
    
    .services .section .sec1 {
        width: 35%;
    }
    .services .section img{
        width:90%;
        margin:50px 0;
    }
    .services .section .secc{
        margin:16px 5px;
       border-bottom: 1px solid rgb(218, 215, 215);
    }
    .services .section .secc.bottom{
        border: none;
    }
}


@media screen and (max-width: 1300px) {
    .services {
        height: auto;
        width: 95%;
    }
    .services h1{
        font-size: 1rem;
    }
    .services p{
        font-size: 14px;
    }
    .services .section {
        padding: 20px;
    }
    
    .services .section .sec {
        width: 22%;
    }
    
    .services .section .sec1 {
        width: 35%;
    }
    .services .section img{
        width:90%;
    }
}


/* For laptops and smaller desktop screens */
@media screen and (max-width: 1024px) {
    .services {
        height: auto;
        width: 95%;
    }
    .services h1{
        font-size: 1rem;
    }
    .services p{
        font-size: 14px;
    }
    .services .section {
        padding: 20px;
    }
    
    .services .section .sec {
        width: 22%;
    }
    
    .services .section .sec1 {
        width: 35%;
    }
    .services .section img{
        width:90%;
    }
}
@media screen and (max-width:780px){
    .services h4{
       font-size: 0.8rem;
    }
}


@media screen and (max-width:768px){
    .services {
        width: 100%;
        height:auto;
        background-color: transparent;
        padding:0px;
        margin:0;
    }
    
    .services .section {
        flex-direction: column;
        padding: 0px;
        padding-right:10px;
    }
    
    .services .section .sec {
        width: 100%;
        margin-bottom: 15px;
        margin-top:-28px;
    }
    
    .services .section .sec1 {
    display:none;
    }
    
    .services .section .sec2 {
        margin-top:0px;
         }
    
         .services .section .sec4-4 {
          border:none;
             }
         
    .services .secc {
        margin: 5px 0;
    }
    
    .services .sec1-2 {
        margin-top: 30px;
    }
}
/* For mobile phones */
@media screen and (max-width: 800px) {
    .services {
        transform: none;
        display: block;
        opacity: 1;
        width: 100%;
        height:auto;
        background-color: #fff;
        padding:8px;
        margin:0;
        padding-bottom: 0;
        box-shadow: none;
        position: unset;
        p{
            display: none;
        }
    }
    
    .services .section {
        flex-direction: column;
        padding: 0px;
        padding-right:10px;
    }
    
    .services .section .sec {
        width: 100%;
        margin-bottom: 0;
        margin-top:0;
        h4{line-height: 24px;
            font-size: 12px;
            text-transform: capitalize;
            margin: 0;
            font-weight: 400;
        }
    }
    
    .services .section .sec1 {
    display:none;
    }
    
    .services .section .sec2 {
        margin-top:0px;
         }
    
         .services .section .sec4-4 {
          border:none;
             }
         
    .services .secc {
        margin: 5px 0;
    }
    
    .services .sec1-2 {
        margin-top: 30px;
    }
    .services .section .secc{
        border: none;
        margin: 0;
    }
}

/* For very small mobile devices */
@media screen and (max-width: 380px) {
    .services {
        width: 100%;
        height:auto;
        background-color: transparent;
        padding:0px;
        margin:0;
    }
    
    .services .section {
        flex-direction: column;
        padding: 0px;
        padding-right:10px;
    }
    
    .services .section .sec {
        width: 100%;
        margin-bottom: 15px;
        margin-top:-28px;
    }
    
    .services .section .sec1 {
    display:none;
    }
    
    .services .section .sec2 {
        margin-top:0px;
         }
    
         .services .section .sec4-4 {
          border:none;
             }
         
    .services .secc {
        margin: 5px 0;
    }
    
    .services .sec1-2 {
        margin-top: 30px;
    }
}