.aboutt:hover  .about, .nav-service:hover .services,.nav-industries:hover .industries-div{
opacity: 1;
display:block;

}

.aboutt , .nav-service , .nav-industries{
    padding: 26px 0;

    >span{
      margin-left: 0px;
    font-size: 16px;
    cursor: pointer;
    }
}


.nav:hover{
  text-decoration: none;
  background: linear-gradient(90deg, #0A44FF -17.74%, #AE0FFF 138.18%);
  -webkit-background-clip: text;
  color: transparent;
}



.nav{
  font-size: 16px;
  cursor: pointer;
  font-family:'Instrument Sans';
  font-weight:500;
}



