#accordionsection .accordion-title{
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    justify-content: left;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    color: #032d60;
    padding: 16px 0 0;
    background-color: #fff;
    font-family: 'Geist',sans-serif;
    border-radius: 12px;

    @media (max-width: 500px) {
        font-size: 16px;
    }
}

#accordionsection .accordion-heading{
    text-align: left;
    cursor: pointer;
}

#accordionsection  .accordion-item{
    cursor: pointer;
    background-color: #fff;
    margin-bottom: 1rem;
    border-radius: 12px;
    padding: 0 24px 16px;
    box-shadow: 0 0.125rem 0.5rem -0.125rem rgba(24, 24, 24, 0.08), 0 0.5rem 0.75rem -0.125rem rgba(24, 24, 24, 0.16);
    @media (max-width: 500px) {
        padding: 0 10px 16px;
    }
}
#accordionsection .accordioin-content.active{
    /* padding-bottom: 16px; */
    background-color: #fff;
    text-align: left;
    padding: 0 24px 16px;
}
#accordionsection .accordion-contentP{
    margin: 0;
    text-align: left;
    padding-left: 52px;
}
#accordionsection .accordion-btn{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eaf5fe;
    margin-right: 6px;
    cursor: pointer;
    @media (max-width:500px){
        font-size: 20px;
        height: auto;
        width: auto;
        padding: 8px 11px;
    }
}