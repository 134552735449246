@import url('https://fonts.googleapis.com/css2?family=Geist:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap');

.digital-wrapper{
    background: #000;
    padding: 80px 0;
    .digital{color:#fff;
   
        max-width: 80%;
        margin: 0 auto;
         font-family: Geist, sans-serif;
     
         .main-heading {
           
             h1{max-width: 1100px;
                 width: 80vw;
                 margin: 0 auto;
                 color:#fff;
            text-align: center;
             font-size:70px;
             font-weight: 400;
             @media (max-width:1300px){
                font-size:64px;
             }
             @media (max-width:1100px){
                font-size:50px;
             }
           
                 
             span{ position: relative;
                
                 .line{
                     position: absolute;
                     bottom: 30%;
                    
                     right: 0;
         width: 100%;
                 }
                 .line-text{
                     position: absolute;
                     left: 100%;
                     max-width: 200px;
     
                 }
                 .line-arrow{
                     position: absolute;
                     left: 106%;
                     max-width: 20px;
                     bottom: 20px;
                 }
             }
            
         }
     }  
     
     
     .cards-wrapper{
        margin-top: 50px;
     display:flex;
     gap:2rem;
     
     
     .card{height: fit-content;
         background-color: #171717;
         border: 1px solid #303030;
         flex-direction: column;
         flex: 1;
         align-items: stretch;
         display: flex;
         border-radius: 12px;
         padding: 15px;
         flex-direction: column;
         column-gap: 12px;
         transform: translate3d(0px, -23.9903px, 0px) scale3d(0.980653, 0.980653, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); 
         transform-style: preserve-3d;
     
         h4{text-align: left;
             margin: 0;
             font-size :27px;
     font-weight: 500;
         }
         p{color:rgb(180, 180, 180);
             font-size :12px;
             margin-top: 0;
             margin-bottom: 70px;
             text-align: left;
             
         }
         video,img{overflow: hidden;
             width:100%;
             height: auto;
             border-radius: 10px;
         }
     }

     .card.card2{
        margin-top: 40px;
     }
     .card.card3{
        margin-top: 80px;
     }
     
     }
     
     }
}


@media (max-width:1100px){
    .digital-wrapper {
        & .digital {
            & .main-heading {
                & h1 {
                    & span {
                        .line-text {
                            max-width: 140px;
                        }

                        .line-arrow{
                            bottom: 5px;
                        }
                    
                    }}}}}

}

@media (max-width: 900px) {
    .digital-wrapper {
        & .digital {
            & .main-heading {
                & h1 {                    width: 80%;
                    /* font-size: 55px; */
                }
            }

            .cards-wrapper {
                flex-direction: column;
                
                .card.card3 {
                    margin-top: 0px;
                }
                .card.card2 {
                    margin-top: 0px;
                }
                & .card {
                    h4 {font-size: 30px;
                    }
                    p {
                       
                        font-size: 18px;
                        
                    }
                }
            }
        }
    }
}


@media (max-width:768px){
    .digital-wrapper {
        & .digital {
            max-width:85%;
            & .main-heading {
                & h1 {text-align: left;
                    width: 100%;
                    font-size: 40px;
                    & span {
                        .line-text {
                            max-width: 120px;
                        }

                        .line-arrow{
                            bottom: 5px;
                        }
                }
            }
        }

        .cards-wrapper {
           
            & .card {
                h4 {font-size: 26px;
                }
                p {
                   
                    font-size: 14px;
                    
                }
            }
        }
    }
}
}


@media (max-width:576px){
    .digital-wrapper {
        & .digital {
            max-width:90%;
        }
    }
}



