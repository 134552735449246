/* General Reset Styles */

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
/* body,
h1,
h2,
ul,
li,
p,
figure,
img,
a {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
} */
#NewOurWork{
  background-color: #000000;
  padding-bottom: 6rem;

  ul{
    list-style: none !important;
  }
}

#NewOurWork{
  ul{list-style: none;
  padding: 0;}
}

/* body {
  font-family: "Inter", sans-serif;
  line-height: 1.6;
  color: #333;
} */
/* these are default */
.figure {
  display: block;
  margin: 0;
  padding: 0;
}


/* ------------------------------------------------------------------------------ */

.brand_success {
  text-align: center;
  margin-top: 185px;
  position: relative;
  padding: 46px 40px 40px;
}

.brand_success:before {
  content: "";
  position: absolute;
  padding: 10px;
  inset: 0;
  border-radius: 26px;
  border: 4px solid transparent;
  background: linear-gradient(180deg, #00dbd9, #4186fe) border-box;
  mask: linear-gradient(#fff 0 0) padding-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: 0;
}

/*  --------------------fitst (1) *--------------------*/

.project_list_item >a{
  text-decoration: none;
}

.projects_list_wrap .project_list_item .project_card {
  border-radius: 20px;
  background-color: transparent;
  padding: 25px 24px;
  display: flex;
  justify-content: space-between;
}

.projects_list_wrap .project_list_item.job_get_project {
  margin-top: 95px;
}

.projects_list_wrap .project_list_item .project_card .column_banner {
  flex-shrink: 0;
  width: 50%;
}

.projects_list_wrap .project_list_item .project_card .column_content {
  padding-right: 37px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*---------------------------------- first one-------------------- */

.portfolio_top_head {
  /* padding: 255px 0 105px; */
}

.projects_list_wrap .project_list_item .project_card:hover {
  background-color: #141414;
}

.projects_list_wrap .project_list_item {
  margin-top: 140px;
}

.projects_list_wrap .project_list_item .project_card {
  border-radius: 20px;
  background-color: transparent;
  padding: 25px 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.projects_list_wrap .project_list_item .project_card .column_content {
  padding-right: 37px;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.projects_list_wrap .project_list_item .project_card .column_content .logo {
  margin: 0;
  margin-bottom: 32px;
  max-width: 200px;
}

.projects_list_wrap .project_list_item .project_card .column_content .logo img {
  max-width: 100%;
  width: auto;
  margin: 0;
}

.projects_list_wrap .project_list_item .project_card .column_content .heading2 {
  margin: 0 0 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.4px;
  max-width: 375px;
  color: #fff;
}

.inline_list {
  display: flex;
  padding-bottom: 8px;
}

.inline_list > li strong {
  display: block;
  font-size: 20px;
  line-height: 33px;
  font-weight: 900;
}

.projects_list_wrap
  .project_list_item
  .project_card
  .column_content
  .inline_list
  li:first-of-type {
  max-width: 150px;
}

.inline_list > li:first-of-type {
  padding-left: 0;
  padding-right: 30px;
  /* border-right: 1px solid #fff; */
}

.inline_list > li {
  color: #fff;
  font-size: 11px;
  line-height: 16.5px;
  width: 200px;
  padding-left: 25px;
}

.common__btn {
  margin-top: 40px;
  display: table;
}

.projects_list_wrap
  .project_list_item
  .project_card
  .column_content
  .view_case_study {

  background-color: #0363ff;
  border-color: #0363ff;
  font-size: 12px;
}

.common__btn .btn-effect {
  position: relative;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  border: 2px solid #fff;
  padding: 14px 50px 14px 25px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
}

.common__btn svg {
  right: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #fff;
  width: 18px;
  position: absolute;
  height: 18px;
  stroke-width: 1;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transform: translateX(-5px);
  -webkit-transform: translateX(-5px);
  -moz-transform: translateX(-5px);
  -ms-transform: translateX(-5px);
  -o-transform: translateX(-5px);
}

.projects_list_wrap .project_list_item .project_card .column_banner {
  flex-shrink: 0;
  width: 50%;
}

.projects_list_wrap .project_list_item .project_card .column_banner figure {
  height: 100%;
  overflow: hidden;
  z-index: 1;
  position: relative;
  width: 100%;
  margin: 0;
}

.projects_list_wrap .project_list_item .project_card .column_banner figure img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover; /* Ensures image covers the area but may crop */
  object-position: center; /* Centers the image in the container */
  display: block; /* Prevents unwanted space below the image */
}
.projects_grid .grid_item .project_card .banner_wrap img {
  border-radius: 12px;
  width: 100%;
  box-shadow: 0 6px 26px rgba(0, 0, 0, 0.16);
}

/* ---------------------------------------second one --------------------------------------- */

.global_leaders_section {
  margin-top: 165px;
}

.global_leaders_section .heading2 {
  font-size: 45px;
  line-height: 58.5px;
  margin-bottom: 45px;
  letter-spacing: -0.03em;
  color: #fff;
}

.global_leaders_section .global_leaders_grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;
  margin-bottom: 150px;
}

.global_leaders_section .global_leaders_grid .grid_item .card {
  border-radius: 11px;
  padding: 46px 25px 44px;
  height: 100%;
  background-color: #141414;
  min-height: 285px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.global_leaders_section .global_leaders_grid .grid_item .card .card_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 54px;
  margin-bottom: 100px;
}

.global_leaders_section
  .global_leaders_grid
  .grid_item.kfc
  .card_head
  figure
  img {
  width: 92px;
}

.global_leaders_section
  .global_leaders_grid
  .grid_item
  .card
  .card_head
  .know_more {
  margin: 0;
  display: none;
}

.global_leaders_section
  .global_leaders_grid
  .grid_item
  .card
  .inline_list
  li:first-of-type {
  width: 170px;
}
.global_leaders_section .global_leaders_grid .grid_item .card .inline_list li {
  color: #fff;
  border-color: #b9b9b9;
}

.inline_list > li:first-of-type {
  padding-left: 0;
  padding-right: 30px;
  /* border-right: 1px solid #fff; */
}

.inline_list > li {
  color: #fff;
  font-size: 11px;
  line-height: 16.5px;
  width: 200px;
  padding-left: 12px;
}

.global_leaders_section
  .global_leaders_grid
  .grid_item
  .card
  .inline_list
  li
  strong {
  color: #fa7e6a;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
}

.global_leaders_section
  .global_leaders_grid
  .grid_item
  .card
  .inline_list
  li:not(:nth-of-type(1)) {
  padding-left: 35px;
}

.global_leaders_section .global_leaders_grid .grid_item .card .inline_list li {
  color: #fff;
  border-color: #b9b9b9;
}

.global_leaders_section
  .global_leaders_grid
  .grid_item
  .card
  .inline_list
  li
  strong {
  color: #fa7e6a;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
}

.global_leaders_section .global_leaders_grid .grid_item .card:hover {
  background-color: #1163fb;
}

.global_leaders_section .global_leaders_grid .grid_item .card:hover .card_body {
  color: white;
}

.global_leaders_section
  .global_leaders_grid
  .grid_item
  .card:hover
  .inline_list
  li
  strong {
  color: #fff; /* Change color to white on hover */
}

/* ----------------------------------------- Third One--------------------------------------------- */

.projects_grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
  margin: 0;
}

.projects_grid .grid_item.mt-80 {
  margin-top: 80px;
}

.projects_grid .grid_item .project_card {
  padding: 52px 20px 23px;
  border-radius: 20px;
}

.projects_grid .grid_item .project_card .card_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
  min-height: 66px;
}

.projects_grid .grid_item .project_card .know_more .btn_blue {
  font-size: 12px;
  border-color: transparent !important;
  background-color: transparent !important;
}

.projects_grid .grid_item .project_card .para {
  margin: 0 0 64px;
  font-size: 18px;
  color: #fff;
  line-height: 23.4px;
}

.projects_grid .grid_item .project_card .know_more {
  display: none;
  margin: 0;
}

.projects_grid .grid_item .project_card .know_more .btn_blue {
  font-size: 12px;
  border-color: transparent !important;
  background-color: transparent !important;
}

.projects_grid .grid_item .project_card:hover {
  background-color: #141414;
}

.projects_grid .grid_item .project_card:hover .know_more {
  display: block; /* or flex, depending on your layout */
}

.projects_grid .grid_item .project_card .know_more .btn_blue:hover {
  background-color: #0363ff !important;
}

/* -----------------------------------------Fourth one-------------------------------- */

.talk_to_experts {
  padding: 54px 62px 57px;
  margin-bottom: 155px;
}

.brand_success {
  text-align: center;
  margin-top: 185px;
  position: relative;
  padding: 16px;
  @media (max-width:576px) {margin-top: 50px;
    
  }
}

.brand_success:before {
  content: "";
  position: absolute;
  padding: 10px;
  inset: 0;
  border-radius: 26px;
  border: 4px solid transparent;
  background: linear-gradient(180deg, #00dbd9, #4186fe) border-box;
  mask: linear-gradient(#fff 0 0) padding-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: 0;
}

.talk_to_experts .heading2 {
  font-weight: 400;
}

.brand_success .heading2 {
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 32px;
}
.txt_gradient {
  background: linear-gradient(to right, #01dbda, #4384ff);
  background-clip: text;
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading2 span,
.heading3 span {
  position: relative;
}

.brand_success .common__btn {
  margin: 43px auto 0;
}

.common__btn .btn-effect {
  position: relative;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  border: 2px solid #fff;
  padding: 14px 50px 14px 25px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
}

.brand_success .heading2 {
  color: #fff;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 32px;
}

.btn_blue {
  background-color: #0363ff;
  border-color: #0363ff !important;
  font-size: 14px;
}

/* ---------------------------------Fifth one---------------------------- */

.partner_with_us {
  margin-top: 80px;
  background-image: url(//appinventiv.com/wp-content/themes/twentynineteen-child/css/../new-images/partner-with-us.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

.partner_with_us .inner_wrap {
  position: relative;
  padding: 215px 40px 270px;
  text-align: center;
}

.partner_with_us h2 {
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 56px;
  color: #fff;
}

.partner_with_us .partner_btn {
  margin-left: auto;
  margin-right: auto;
}

.partner_with_us .partner_btn {
  margin-left: auto;
  margin-right: auto;
}

.partner_with_us .bubble.google {
  top: 253px;
  right: 104px;
}
.partner_with_us .bubble.google img {
  animation: walkAround 5s linear infinite;
}

.partner_with_us .bubble.bubble-lg {
  width: 112px;
  height: 112px;
}

.partner_with_us .bubble:before {
  /* content: ""; */
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: 0 0;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  position: absolute;
  border: 1px solid #707070;
}

.partner_with_us .bubble.google img {
  max-width: 84px;
}
.partner_with_us .bubble img {
  z-index: 1;
}

.partner_with_us .bubble.kfc {
  top: 100px;
  left: 150px;
}

.partner_with_us .bubble.bubble-lg {
  width: 112px;
  height: 112px;
}
.partner_with_us .bubble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
}

.bubble.kfc {
  -webkit-animation-name: kfc_floating;
  -webkit-animation-duration: 21s;
  animation-name: kfc_floating;
  animation-duration: 21s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.bubble {
  transition: 0.1s;
}

.partner_with_us .bubble.kfc img {
  max-width: 84px;
  animation: walkAround 5s linear infinite;
}

.partner_with_us .bubble img {
  /* animation: walkAround 5s linear infinite; */
  z-index: 1;
}

/* .partner_with_us .bubble:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: 0 0;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  position: absolute;
  border: 1px solid #707070;
  animation: walkAround 5s linear infinite;
} */

.partner_with_us .bubble.pizzahut {
  top: 311px;
  left: 150px;
}
.partner_with_us .bubble.bubble-sm {
  width: 46px;
  height: 45px;
}

.partner_with_us .bubble.pizzahut img {
  animation: walkAround 5s linear infinite;
  max-width: 30px;
}

.partner_with_us .bubble.adidas {
  bottom: 204px;
  left: 295px;
}
.partner_with_us .bubble.bubble-md {
  width: 90px;
  height: 90px;
}

.partner_with_us .bubble.adidas img {
  max-width: 55px;
  animation: walkAround 5s linear infinite;
}

.partner_with_us .bubble.ikea {
  bottom: 160px;
  right: 190px;
}
.partner_with_us .bubble.bubble-md {
  width: 90px;
  height: 90px;
}

.partner_with_us .bubble.ikea img {
  animation: walkAround 5s linear infinite;
  max-width: 69px;
}

.partner_with_us .bubble.dominos {
  bottom: 136px;
  left: 478px;
}
.partner_with_us .bubble.bubble-sm {
  width: 46px;
  height: 45px;
}

.partner_with_us .bubble.dominos img {
  animation: walkAround 5s linear infinite;
  max-width: 24px;
}

.partner_with_us .bubble.kpmg {
  animation: walkAround 5s linear infinite;
  top: 117px;
  right: 211px;
}
.partner_with_us .bubble.bubble-sm {
  width: 46px;
  height: 45px;
}
.partner_with_us .bubble.kpmg img {
  max-width: 34px;
}

.partner_with_us .bubble.adidas img {
  max-width: 55px;
  animation: walkAround 5s linear infinite;
}

@keyframes walkAround {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(50px, 0);
  }
  50% {
    transform: translate(50px, 50px);
  }
  75% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.global_leaders_section .global_leaders_grid .grid_item .card .card_head:hover .know_more {
  display: block;
  font-size: 16px;
  font-weight: 500;
}



@media (max-width: 991px) {
  .partner_with_us .bubble img {
    animation: none;
  }

  /* Remove the walkAround animation from each bubble image */
  .partner_with_us .bubble.kfc img,
  .partner_with_us .bubble.pizzahut img,
  .partner_with_us .bubble.adidas img,
  .partner_with_us .bubble.ikea img,
  .partner_with_us .bubble.dominos img,
  .partner_with_us .bubble.kpmg img {
    animation: none;
  }
}

.partner_with_us .bubble.google img {
  animation: walkAround 5s linear infinite;
}

.partner_with_us .bubble.bubble-lg {
  width: 112px;
  height: 112px;
}

.partner_with_us .bubble:before {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: 0 0;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  position: absolute;
  border: 1px solid #707070;
}

.partner_with_us .bubble img {
  z-index: 1;
}

.partner_with_us .bubble.kfc {
  top: 100px;
  left: 150px;
}

.partner_with_us .bubble.bubble-lg {
  width: 112px;
  height: 112px;
}

.partner_with_us .bubble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
}

.bubble.kfc {
  -webkit-animation-name: kfc_floating;
  -webkit-animation-duration: 21s;
  animation-name: kfc_floating;
  animation-duration: 21s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.bubble {
  transition: 0.1s;
}

.partner_with_us .bubble.kfc img {
  max-width: 84px;
}

.partner_with_us .bubble.pizzahut {
  top: 311px;
  left: 150px;
}

.partner_with_us .bubble.bubble-sm {
  width: 46px;
  height: 45px;
}

.partner_with_us .bubble.adidas {
  bottom: 204px;
  left: 295px;
}

.partner_with_us .bubble.bubble-md {
  width: 90px;
  height: 90px;
}

.partner_with_us .bubble.ikea {
  bottom: 160px;
  right: 190px;
}

.partner_with_us .bubble.bubble-md {
  width: 90px;
  height: 90px;
}

.partner_with_us .bubble.dominos {
  bottom: 136px;
  left: 478px;
}

.partner_with_us .bubble.bubble-sm {
  width: 46px;
  height: 45px;
}

.partner_with_us .bubble.kpmg {
  top: 117px;
  right: 211px;
}

.partner_with_us .bubble.bubble-sm {
  width: 46px;
  height: 45px;
}

.partner_with_us .bubble.kpmg img {
  max-width: 34px;
}

.partner_with_us .bubble.adidas img {
  max-width: 55px;
}

@keyframes walkAround {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(50px, 0);
  }
  50% {
    transform: translate(50px, 50px);
  }
  75% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.global_leaders_section .global_leaders_grid .grid_item .card .card_head:hover .know_more {
  display: block;
  font-size: 16px;
  font-weight: 500;
}



/* ------------------------------------------first (1.1)---------------------- */

/* .portfolio_top_head {
  padding: 255px 0 105px;
} */

.container {
  /* max-width: 1500px; */
  margin: 0 auto;
}

.top_content_wrap {
  text-align: center;
  position: relative;
  z-index: 3;
}

.portfolio_top_head .sm-title {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  margin: 0;
}

.portfolio_top_head .heading1 {
  margin: 25px 50px;
  justify-content: center;
  line-height: 1;
}

.txt_gradient_top {
  background: linear-gradient(to right, #00dbd9, #4284ff 50%, #fff 50%);
  position: relative;
  background-size: 200% 100%;
  background-position: 100%;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transition: background-position 0.5s ease-in-out; /* Ensure transition is applied here */
}

.txt_gradient_top.active {
  background-position: 0; /* This triggers the smooth transition */
}

@keyframes gradientCycle {
  0%,
  33.33% {
    background-position: 100%;
  }
  50%,
  83.33% {
    background-position: 0;
  }
  100% {
    background-position: 100%;
  }
}

.txt_gradient_top {
  background: linear-gradient(to right, #00dbd9, #4284ff 50%, #fff 50%);
  position: relative;
  background-size: 200% 100%;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 92px; /* Increased font size */
  font-weight: bold; /* Optional: To make it more prominent */
  line-height: 1.2; /* Adjust line height for better spacing */
}

.txt_gradient_wrap {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.txt_gradient_top:nth-child(1) {
  animation: gradientCycle 9s infinite ease-in-out;
  animation-delay: 0s;
}

.txt_gradient_top:nth-child(2) {
  animation: gradientCycle 9s infinite ease-in-out;
  animation-delay: 3s;
}

.txt_gradient_top:nth-child(3) {
  animation: gradientCycle 9s infinite ease-in-out;
  animation-delay: 6s;
}

.ds_flex {
  display: flex;
}

.portfolio_top_head .app__subhead {
  font-size: 20px;
  max-width: 838px;
  margin: 0 auto;
  line-height: 30px;
  color: #fff;
}

.kfc_cta:before {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.1);
  background-position: center;
  width: 90%;
  height: calc(100% + 80px);
  top: -60px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  filter: blur(45px);
  -webkit-filter: blur(45px);
  z-index: -1;
}

.cta_section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  padding: 110px 45px;
  margin-left: auto;
  margin-right: auto;
  height: 491px;
  margin-top: 150px;
}

.metaSpaceLink{
  text-decoration: none;
}
.kfc_cta .cta_logo {
  margin-bottom: 40px;
}

.kfc_cta .cta_logo img {
  max-width: 100%;
}

.kfc_cta .cta_text_wrap {
  width: calc(100% + 20px);
  margin: 0 -10px;
  color: #fff;
  align-items: flex-end;
}

.kfc_cta .cta_text_wrap .column_grid {
  display: flex;
  margin: 0 -10px;
}

.kfc_cta .cta_text_wrap {
  width: calc(100% + 20px);
  margin: 0 -10px;
  color: #fff;
  align-items: flex-end;
}

.kfc_cta .cta_text_wrap .col-6.col-left {
  flex: 0 0 58%;
  width: 58%;
}

.kfc_cta .cta_text_wrap .para {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

.kfc_cta .cta_text_wrap .col-6.col-right {
  flex: 0 0 42%;
  width: 42%;
}

.kfc_cta .cta_text_wrap .col-6 {
  flex: 0 0 50%;
  width: 50%;
  padding: 0 10px;
}

.kfc_cta .cta_text_wrap .column_grid {
  display: flex;
  margin: 0 -10px;
}

.list3 .inline_list li {
  width: auto;
}

.inline_list {
  display: flex;
  padding-bottom: 8px;
  padding-left: 0;
  @media(max-width:576px){
    gap: 6px;
  
    >li{text-align: center;
      padding-right: 10px !important;
    }
  }
}

.flex_wrapper {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  flex-wrap: wrap;
}

.kfc_cta .cta_logo {
  margin-bottom: 40px;
}

.kfc_cta {
  margin-bottom: 20px;
  min-height: 470px;
  border-radius: 12px;

  padding: 240px 60px 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
  position: relative;
}
.cta_section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  /* align-items: center; */
  padding: 110px 45px;
  margin-left: auto;
  margin-right: auto;
  height: 491px;
  margin-top: 150px;
  @media(max-width:576px){
    margin-top: 40px;
  }
}
.portfolio_top_head .heading1 {
  margin: 25px 50px;
  justify-content: center;
  line-height: 1;
}

.top_head_wrap {
  background: unset;
  @media (max-width:1025px){
    margin: 0 auto;
    width: 90%;
  }
}

/* ---------------------------- Media Queries ---------------------------- */

/* For Mobile Devices (Less than 768px) */
@media (max-width: 767px) {
  /* body {
    padding: 30px;
  } */
  .global_leaders_section .global_leaders_grid {
    grid-template-columns: 1fr; /* Single column on small screens */
    gap: 16px; /* Small gap on mobile */
  }
  .projects_grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 16px;
    margin: 0;
  }
  .container {
    width: 100%;
    padding: 0 10px;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    padding: 0 ;
  }

  .portfolio_top_head {
    padding: 100px 0;
  }

  .projects_list_wrap .project_list_item .project_card {
    flex-direction: column;
    padding: 15px;
  }

  .projects_list_wrap .project_list_item .project_card .column_content,
  .projects_list_wrap .project_list_item .project_card .column_banner {
    width: 100%;
  }

  .projects_list_wrap .project_list_item .project_card .column_content {
    padding-right: 0;
    padding-bottom: 20px;
  }

  .projects_list_wrap
    .project_list_item
    .project_card
    .column_banner
    figure
    img {
    /* height: 300px; */
    object-fit: cover;
  }

  .projects_list_wrap
    .project_list_item
    .project_card
    .column_content
    .heading2 {
    font-size: 14px;
    margin: 0 0 20px;
  }

  .projects_list_wrap
    .project_list_item
    .project_card
    .column_content
    .inline_list {
    /* flex-direction: column; */
  }

  .inline_list > li {
    width: 100%;
    /* padding-left: 0; */
    padding-bottom: 8px;
  }

  .common__btn .btn-effect {
    padding: 12px 25px;
  }

  .common__btn svg {
    width: 15px;
    height: 15px;
  }
}

/* For Tablet Devices (Between 768px and 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    width: 100%;
    margin: 0 auto;
  }

  /* .projects_list_wrap .project_list_item .project_card {
    border-radius: 20px;
    background-color: transparent;
    padding: 25px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-wrap: wrap;
  } */

  .global_leaders_section .global_leaders_grid {
    grid-template-columns: repeat(
      2,
      minmax(250px, 1fr)
    ); /* Two columns on tablets */
    gap: 20px; /* Slightly smaller gap for tablets */
  }

  .projects_list_wrap .project_list_item .project_card {
    padding: 20px;
  }

  .projects_list_wrap .project_list_item .project_card .column_content {
    width: 50%;
    padding-right: 20px;
  }

  .projects_list_wrap .project_list_item .project_card .column_banner {
    width: 50%;
  }

  .projects_list_wrap
    .project_list_item
    .project_card
    .column_content
    .heading2 {
    font-size: 15px;
    margin: 0 0 30px;
  }

  .projects_list_wrap
    .project_list_item
    .project_card
    .column_content
    .inline_list {
    /* flex-direction: column; */
  }

  .inline_list > li {
    width: 100%;
    /* padding-left: 0; */
    padding-bottom: 10px;
  }

  .common__btn .btn-effect {
    padding: 14px 35px;
  }

  .common__btn svg {
    width: 18px;
    height: 18px;
  }
}

/* For Larger Screens (More than 1024px) */
@media (min-width: 1025px) {
  .container {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    /* padding: 30px; */

    padding-left: 30px; /* Add space on the left */
    padding-right: 30px; /* Add space on the right */
    box-sizing: border-box; /* Ensures padding doesn't increase width */
  }
  #NewOurWork .top_head_wrap{
    max-width: 90%;
    margin: 0 auto;
  } 

  .projects_list_wrap .project_list_item .project_card {
    padding: 25px;
  }

  .projects_list_wrap .project_list_item .project_card .column_content {
    width: 45%;
    padding-right: 37px;
  }

  .projects_list_wrap .project_list_item .project_card .column_banner {
    width: 50%;
  }

  .projects_list_wrap
    .project_list_item
    .project_card
    .column_content
    .heading2 {
    font-size: 16px;
  }

  .inline_list > li {
    width: 200px;
    padding-left: 25px;
  }

  .common__btn .btn-effect {
    padding: 14px 50px;
  }

  .common__btn svg {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 576px) {
  .projects_list_wrap .project_list_item .project_card {
    border-radius: 20px;
    background-color: transparent;
    padding: 25px 24px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .top_content_wrap {
    padding: 0 15px;
  }

  .portfolio_top_head .sm-title {
    font-size: 16px;
  }

  .top_head_wrap .heading1 {
    font-size: 22px;
    margin: 25px 0;
  }

  .portfolio_top_head .app__subhead {
    font-size: 14px;
    line-height: 24px;
  }

  .portfolio_top_head {
    padding: 150px 0 45px;
  }

  .flex_wrapper {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .kfc_cta .cta_text_wrap .col-6.col-left,
  .kfc_cta .cta_text_wrap .col-6.col-right {
    flex: auto;
    width: 100%;
  }

  .kfc_cta .cta_text_wrap .col-6.col-right {
    padding-top: 170px;
  }

  .list3 {
    justify-content: flex-start;
  }

  .kfc_cta {
    padding: 60px 15px 40px;
    justify-content: flex-start;
  }

  .kfc_cta .cta_text_wrap .column_grid {
    margin: 0;
  }

  .kfc_cta .cta_logo {
    margin-bottom: 20px;
  }

  .kfc_cta .cta_text_wrap .para {
    font-size: 14px;
  }

  .projects_list_wrap .project_list_item {
    margin-top: 50px;
  }

  .projects_list_wrap .project_list_item .project_card {
    flex-direction: column;
    padding: 20px 15px;
    gap:20px;
  }

  .projects_list_wrap .project_list_item .project_card .column_content,
  .projects_list_wrap .project_list_item .project_card .column_banner {
    width: 100%;
    padding-right: 0;
  }

  .projects_list_wrap
    .project_list_item
    .project_card
    .column_content
    .heading2 {
    font-size: 14px;
  }

  .projects_list_wrap .project_list_item .project_card .column_banner {
    /* margin-top: 30px; */
  }

  .common__btn {
    margin-top: 20px;
  }

  .projects_list_wrap .project_list_item .project_card .column_content .logo {
    margin-bottom: 20px;
  }

  .projects_list_wrap .project_list_item.job_get_project {
    margin-top: 25px;
  }

  .projects_list_wrap
    .project_list_item.job_get_project
    .project_card
    .column_banner {
    order: 1;
  }

  /* .brand_success {
    padding: 46px 15px 40px;
    margin: 75px 15px;
  } */

  .brand_success .brands {
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 0;
    justify-content: flex-start;
  }

  .brand_success .heading2 {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  .brand_success br,
  .global_leaders_section .heading2 br {
    display: none;
  }

  .brand_success .brands li {
    width: 50%;
  }

  .global_leaders_section {
    margin-top: 65px;
    padding: 0 15px;
  }

  .global_leaders_section .heading2 {
    font-size: 28px;
    line-height: 1.2;
    margin-bottom: 30px;
  }

  .global_leaders_section .global_leaders_grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .global_leaders_section .global_leaders_grid .grid_item .card .card_head {
    margin-bottom: 50px;
  }

  .inline_list br,
  .project_card br {
    display: none;
  }

  .projects_grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .projects_grid .grid_item.mt-80 {
    margin: 0;
  }

  .projects_grid .grid_item .project_card .para {
    margin: 0 0 35px;
  }

  .global_leaders_section .global_leaders_grid {
    margin-bottom: 0;
  }

  .case_studies_grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    -moz-column-gap: 16px;
    column-gap: 0;
    row-gap: 70px;
    margin: 70px 15px;
  }

  .projects_list_wrap .project_list_item:nth-child(2) .column_banner {
    order: 2;
  }

  .partner_with_us {
    margin-top: 80px;
  }

  .partner_with_us .inner_wrap {
    padding: 150px 15px;
  }

  .partner_with_us h2 {
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 46px;
  }

  .partner_with_us .bubble.google {
    top: 20px;
    right: 104;
  }

  .partner_with_us .bubble.kfc {
    top: 0;
    left: 20px;
  }

  .partner_with_us .bubble.pizzahut {
    top: 331px;
    left: 10px;
  }

  .partner_with_us .bubble.adidas {
    bottom: 30px;
    left: 255px;
  }

  .partner_with_us .bubble.ikea {
    bottom: 30px;
    right: auto;
    left: 40px;
  }

  .partner_with_us .bubble.dominos {
    bottom: 136px;
    left: 478px;
  }

  .partner_with_us .bubble.kpmg {
    top: 0;
    right: 50px;
  }
  .project_card.card2{
flex-direction: column-reverse !important;
  }
}

@media (max-width: 639px) {
  .global_leaders_section .global_leaders_grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-bottom: 0;
  }
}

/* Base (default for larger screens) */
.portfolio_top_head .heading1 .txt_gradient_top {
  font-size: 82px; /* Default size for large screens */
  font-weight: bold;
  line-height: 1.2;
}

/* Medium Screens (Tablets and smaller desktops) */
@media (max-width: 1024px) {
  .portfolio_top_head .heading1 .txt_gradient_top {
    font-size: 62px; /* Adjust font size */
  }
}

/* Small Screens (Mobile devices) */
@media (max-width: 768px) {
  .portfolio_top_head .heading1 .txt_gradient_top {
    font-size: 34px; /* Further reduce font size */
  }
}

/* Extra Small Screens (Very small mobile devices) */
@media (max-width: 480px) {
  .portfolio_top_head .heading1 .txt_gradient_top {
    font-size: 20px; /* Smallest size for very small screens */
  }
}

@media (max-width: 767px) {
  .kfc_cta .cta_text_wrap .col-6.col-right {
    padding-top: 170px;
  }
}
@media (max-width: 767px) {
  .kfc_cta .cta_text_wrap .col-6.col-left,
  .kfc_cta .cta_text_wrap .col-6.col-right {
    flex: auto;
    width: 100%;
  }
}

/* -------------------- */
.portfolio_top_head {
  padding-top: 118px;
}
.inline_list > li:first-of-type {
  padding-left: 0;
  padding-right: 13px;
  /* border-right: 1px solid #fff; */
}

.col-right .inline_list > li:nth-child(1){
  border-right: 1px solid #fff;
}

.col-right1 .inline_list > li:nth-child(2) {
  /* padding-left: 0; */
  padding-right: 30px;
  border-right: 1px solid #fff;
}
.col-right2 .inline_list > li:nth-child(3) {
  /* padding-left: 0; */
  padding-right: 30px;
  border-right: 1px solid #fff;
}
.column_content h1{
  color:white;
  text-transform: uppercase;
}
.business {
  display:flex;
  align-items: center;
}
.business li{
  font-size:1rem;
}
/* ------------ */

@media (min-width: 768px) and (max-width: 1024px) {
  .project_card {
    display: flex;
    flex-direction: row; /* Keep horizontal (row) layout */
    justify-content: space-between; /* Optional: adds space between the image and content */
    flex-wrap: nowrap !important;
    gap: 12px;
  }

  .project_card .column_banner,
  .project_card .column_content {
    width: 48%; /* Adjust the width of both image and content to fit in a row */
  }

  .project_card .column_content {
    margin-left: 20px; /* Optional: Add space between the image and content */
  }
}

@media (max-width: 767px) {
  .kfc_cta .cta_text_wrap .col-6.col-right {
    padding-top: 170px;
  }
}

@media (max-width: 1023px) {
  .inline_list {
    padding-bottom: 8px;
  }
}

/* --------Iphone pro max-------- */

@media (max-width: 320px) and (max-height: 568px) {
  .projects_list_wrap
    .project_list_item
    .project_card
    .column_content
    .inline_list {
    display: flex;
    flex-direction: row !important;
  }
}

.kfc_cta {
  margin-bottom: 20px;
  min-height: 470px;
  border-radius: 12px;
  background-image: url('../../Component/Images/metaspace-banner.png');
  background-size: contain;
  padding: 240px 60px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
  position: relative;
  @media(max-width:576px){
    padding: 240px 20px 40px;
  }
}


/* -------------------------------320 only-------------------- */

@media (max-width: 320px) {
  .global_leaders_section .global_leaders_grid .grid_item .card .inline_list li:not(:nth-of-type(1)) {
    padding-left: 10px;
}

.inline_list > li:first-of-type {
  padding-left: 0;
  padding-right: 12px;
  /* border-right: 1px solid #fff; */
}

.inline_list {
  padding-bottom: 8px;
}

.kfc_cta .cta_text_wrap .column_grid {
  margin: 0;
}

.kfc_cta{
  padding: 236px 23px 40px;
  overflow: hidden;
  display: flex;
  /* padding: 0; */
}

.cta_section{
  margin-top: 0;
}

.kfc_cta .cta_text_wrap .col-6.col-right {
  padding-top: 73px;
}
}



/* iPhone 15 Pro and 14 Pro (6.1-inch models) */
@media only screen and (max-width: 390px) and (min-width: 375px) {
  /* Styles for 6.1-inch iPhones */
  .kfc_cta{
    padding: 0 !important;
  }
}

/* iPhone 15 Pro Max and 14 Pro Max (6.7-inch models) */
@media only screen and (max-width: 430px) and (min-width: 390px) {
  /* Styles for 6.7-inch iPhones */
}

/* iPhone 12 Pro and 13 Pro (6.1-inch models) */
@media only screen and (max-width: 390px) and (min-width: 375px) {
  /* Styles for older 6.1-inch iPhones */
}

/* iPhone 12 Pro Max and 13 Pro Max (6.7-inch models) */
@media only screen and (max-width: 428px) and (min-width: 390px) {
  /* Styles for older 6.7-inch iPhones */
}






/* ------------------------------brand sucess------------- */

/* Wrapper for the entire brand success section */
.brand_success {
  text-align: center;
}

/* Adjust slick-slider container */
.brand_success .slick-slider {
  overflow: hidden; /* Prevent overflow issues */
  display: flex;
  align-items: center;
  /* padding: 4px  180px; */
}

/* Individual slides (logos) */
.brand_success .slick-slide {
  padding: 0 7px; /* Ensure space between slides is 14px */
  box-sizing: border-box; /* Keep consistent padding behavior */
}

/* Logo images */
.brand_success img {
  max-width: 100%;
  height: 65px;
  display: block;
  margin: 0 auto; /* Center images */
  padding: 5px;
  object-fit: contain; /* Ensure proper fit within the box */
}

/* Slick list */
.brand_success .slick-list {
  overflow: hidden; /* Prevent extra scrolling content */
}

/* Slick track for scrolling behavior */
.brand_success .slick-track {
  display: flex;
  align-items: center;
  gap: 14px; /* Final space between logos */
  transition: transform 0.5s ease; /* Smooth scrolling effect */
}

/* Adjust logo container */
.brand_success li {
  list-style: none;
  height: 80px; /* Reduce box height */
  display: flex;
  align-items: center;
  justify-content: center;
}



@media (max-width:992px){

  .projects_list_wrap{
    padding-left:0;
  }
}
