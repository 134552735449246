body {
  margin: 0;
  padding: 0;;
  font-family: 'Roboto', sans-serif;
  background-color: #f1f7f5;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
*{
  box-sizing: border-box;
}

.react-tel-input{
  font-family: Roboto, sans-serif;
    font-size: 15px;
    position: relative;
    width: 90% !important;
    margin: 0 auto;
    margin-bottom: -10px;
    @media (max-width:600px){
      width:100% !important;
    }
}

.react-tel-input .special-label{
  /* display: none; */
    white-space: nowrap;
    background: #fff;
    font-size: 13px;
    left: 25px;
    padding: 0 5px;
    position: absolute;
    top: -7px;
    z-index: 1;
}

.react-tel-input .form-control{
  background: #fff;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: #495057;
    font-size: 16px;
   
    width: 300px;

    padding: 24px 0 25px 60px !important;
    width: 100% !important;

    &:hover{
      border: 1px solid #000;
    }

}

.react-tel-input.DetailsInfoPhoneInput .flag-dropdown{
  /* border-radius: 3px 0 0 3px; */
    bottom: 0;
    padding: 0;
    position: absolute;
    top: 0;

    background: transparent;
    background-color: transparent;
    border: 2px solid transparent;
    border-right: 1px solid #e1e1e1;

    .country-list {
      text-align: left;
    }

}
.react-tel-input .flag-dropdown.open {
  background: transparent !important;
}

.react-tel-input .selected-flag{
  border-radius: 3px 0 0 3px;
    height: 100%;
    /* outline: none; */
    padding: 0 0 0 11px;
    position: relative;
    width: 52px;
}

.react-tel-input .selected-flag .flag{
  margin-top: -12px;
    position: absolute;
    top: 50%;
}

.react-tel-input .selected-flag .arrow{
  border-left: 3px solid #0000;
    border-right: 3px solid #0000;
    border-top: 4px solid #555;
    height: 0;
    left: 29px;
    margin-top: -1px;
    position: relative;
    top: 50%;
    width: 0;
}


.react-tel-input .selected-flag::before {
  border: 1px solid #0000;
  border-radius: 4px 2px 2px 4px;
  bottom: 2px;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 2px;
  transition: box-shadow .25s ease, border-color .25s ease;
  width: 100%;

}



.DetailsInfoPhoneInput.connect{
  width: 95% !important;
  margin: 0;

  @media(max-width:768px){
    margin-bottom: 18px;
  }

  .form-control {
    padding: 18px 0 18px 50px !important;
    border: none ;
    border-radius: 0;
    border-bottom:1px solid #C5C5C5;


  }

  .flag-dropdown {
    background: transparent;
    border-radius: 0;
    border: none;
    border-bottom:1px solid #C5C5C5;
  }

}



.DetailsInfoPhoneInput.consult{

  width: 100% !important;

  .form-control {
    padding: 15px 0 15px 50px !important;
    border-radius: 0;
    border:1px solid #dbdbdb;


  }
  .flag-dropdown {
    background: transparent;
    border-radius: 0;
    border:1px solid #dbdbdb;
  }

}






.DetailsInfoPhoneInput.consult.react-tel-input .form-control{
  height:2.8rem !important;
  
}