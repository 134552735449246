
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.about {opacity: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    display: none;
    transform: translate(-50%);
    left: 50%;
    height: fit-content;
    width: 90%;
    background-color: white;
    margin: 0 auto;
    position: fixed;
    top: 70px;
    border: none;
    padding: 40px 35px;
    transition: 0.3s ease-in-out;
}
.about .image{
    border-bottom:1px solid grey;
    /* margin:20px; */
}
.about img{
    margin-bottom: 40px;
    margin-top:20px;
}

.about .top{
    display:flex;
    gap:30px;
    justify-content: space-around;
}
.about h4{
    font-size: 15px;
    font-weight: 700;
    font-family: "Instrument Sans", sans-serif;
    text-transform: uppercase;
}
.about p{
    font-size: 14px;
    font-weight: 400;
    font-family: "Instrument Sans", sans-serif;
}
.about h5{
    font-size: 15px;
    font-weight: 500;
    font-family: "Instrument Sans", sans-serif;
}
.about .data{
    width:20%;
    >a{
        text-decoration: none;
        color: inherit;
    }
}
.about .data2 .pdf{
    display:flex;
    height:50px;
    cursor: pointer;
}
.about .data2 .pdf button{
    border:none;
    background:transparent;
    cursor: pointer;
    padding-left: 0;
}
.about .data2 .pdf img{
    width:15px;
    height:15px;
    position: relative;
    bottom:2px;
}
.about .first{
    width:22%;
}
.about .data1, .data2{
    border-bottom: 1px solid grey;
}
.about .bottom{
    display:flex;
    gap:92px;
    padding:40px;
}


@media screen and (max-width:1500px){
    .about img{
        width:97%;
        height:100px;
    }
    .about .bottom{
        gap:6rem;
        padding: 12px 25px;
    }
    .about .bottom h5 {
        margin-top: 0;
        margin-bottom: 6px;
    }
    .about h4{
        /* font-size: 1rem; */
    }
}


@media screen and (max-width:1350px){
    .about img{
        width:97%;
        height:100px;
    }
    .about .bottom{
        gap:5rem;
        padding: 12px 20px;
    }
    .about .bottom h5 {
        margin-top: 0;
        margin-bottom: 6px;
    }
    .about h4{
        /* font-size: 0.8rem; */
    }
}

@media screen and (max-width:1280px){
   .about{
    padding:35px;
   }
    .about img{
        width:100%;
        height:100px;
    }
    .about .bottom{
        gap:4.8rem;
        padding: 12px 20px;
    }
    .about .bottom h5 {
        margin-top: 0;
        margin-bottom: 6px;
    }
    .about h4{
        /* font-size: 0.8rem; */
    }
}
@media screen and (max-width:1150px){
    .about{
     padding:35px;
    }
     .about img{
         width:100%;
         height:100px;
     }
     .about .bottom{
         gap:4.2rem;
         padding: 12px 20px;
        }
        .about p{
            font-size: 0.8rem;
        }
 }
/* For laptops and smaller desktop screens */
@media screen and (max-width: 1024px) {
    .about {
        height: auto;
        width: 95%;
        padding-bottom: 20px;
    }
    
    .about .bottom {
        gap: 3.5rem;
        padding: 30px;
    }
    .about img{
        width:100%;
        height:100px;
    }
}

@media screen and (max-width: 820px) {
    .about .bottom {
        gap: 2.5rem;
        padding: 30px;
    }
}
/* For tablets and iPads */
@media screen and (max-width: 800px) {
    .about{display: block;
        opacity: 1;
        box-shadow: none;
        position: unset;
        width:100%;
        margin:auto;
           padding:0;
      padding:8px;
      height:auto;
      transform: none;
      background: #fff;
      p, .pdf{
        display: none !important;
      }
     
    }
    .about .first{
        display: none;
    }
    .about .top{
        flex-direction: column;
        gap:0px !important;
    }
    .about .data{
        width:100%;
        border-bottom: none;
    }
    .about .bottom{
        display:block;
        padding:0px;
    }
    .about .bottom .data {
        border: none;
    }
    .about h4{
        font-weight: 400;
        margin: 5px 0;
        font-size: 12px;
        text-transform: capitalize;
    }
    .about p{
        font-size: 0.7rem;
    }
    .about .data2{
        margin-top:10px;
    }
}

/* For mobile phones */
/* @media screen and (max-width: 480px) {
    .about{
        width:100%;
        margin:auto;
      background-color: transparent;
      padding:0;
      padding-right:8px;
      height:46vh;
    }
    .about .first{
        display: none;
    }
    .about .top{
        flex-direction: column;
        gap:0px !important;
    }
    .about .data{
        width:100%;
        border-bottom: 1px solid grey;
    }
    .about .bottom{
        display:block;
        padding:0px;
    }
    .about .bottom .data{
        border: none;
    }
    .about h4{
        font-size: 0.7rem;
    }
    .about p{
        font-size: 0.7rem;
    }
    .about .data2{
        margin-top:10px;
    }
} */

/* For very small mobile devices */
/* @media screen and (max-width: 380px) {
    .about{
        width:100%;
        margin:auto;
      background-color: transparent;
      padding:0;
      padding-right:8px;
      height:46vh;
      
    }
    .about .first{
        display: none;
    }
    .about .top{
        flex-direction: column;
        gap:0px !important;
    }
    .about .data{
        width:100%;
       }
    .about .bottom{
        display:block;
        padding:0px;
    }
    .about .bottom .data{
        border: none;
    }
    .about h4{
        font-size: 0.7rem;
    }
    .about p{
        font-size: 0.7rem;
    }
    .about .data2{
        margin-top:10px;
    }
   
} */