@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');




    .slick-next .custom-prev-arrow {
        bottom:-50px;
        left:10rem !important;
    }

    .comp1 .slick-arrow.slick-prev,   .comp1 .slick-arrow.slick-next{
        top: auto;
        bottom: -70px !important;

        left: 30px;
       
        transform: none !important;
    }

.comp1{
    background-image: url('../../Component/Images/New-accordianBG.png');
    background-size:  100% 100%;
    background-repeat: no-repeat;
margin:0;
padding: 60px 10%;
padding-bottom: 135px;
height:auto;
font-family: "DM Sans", sans-serif;
@media (max-width:1200px){
    /* margin-bottom: 120px; */
}
}
.comp1 .img{
    display: flex;
    justify-self: flex-start;
    width:30px;
    height:30px;
    margin:0;
    position: relative;
    top: 26px;
    left: 16px;
}
.comp1 h1{
    font-size: 3.5rem;
    text-align: left;
    width:40%;
    font-weight: 500;
    padding:0 20px;
    padding-bottom: 20px;
}
.comp1 .carousels{
    text-align: center;
    justify-content: center;
    /* display:flex; */
    /* justify-content: space-around; */
    gap:0;
}
.comp1 .card{
    position: relative;
   background-color: #fff;
    border-radius: 6px;
    /* margin-right: 16px; */
    /* margin-left: 16px; */
   padding: 50px 34px;
   padding-bottom: 132px;
    /* width:445px; */
    /* height:550px; */
    text-align: left;
    width: 90% !important; 
    height:400px;
    box-shadow: 0px 4px 12px 0px #0C44CC1A;
    margin-bottom: 30px;
    @media (min-width:1600px){
        height: 400px;
    }

}
.comp1 .card .imgg{
    width:64px;
    height:64px;
}
.comp1 .card h2{
    font-size: 2rem;
    font-weight: 500;
}
.comp1 .card p{
    font-size: 1.2rem;
    color: #9497A1;
    display: -webkit-box;       
    -webkit-line-clamp: 3;      
    -webkit-box-orient: vertical; 
    overflow: hidden;           
    text-overflow: ellipsis; 
    
}
.comp1 a{
    text-decoration: none;
    background: linear-gradient(90deg, #0A44FF -17.74%, #AE0FFF 138.18%);
    -webkit-background-clip: text; 
    font-family: DM Sans;
    font-size: 19px;
    line-height: 19px;
    font-weight: 500;
    letter-spacing: 0px;
    position:absolute;
    bottom: 20px;
    /* margin-bottom:30px; */
    display:flex;
    gap:10px;
    /* margin-top:30px; */
    color: transparent;
    cursor: pointer;
}
.comp1 a:hover{
    /* border:2px solid #000; */
}
.comp1 a img{
    width:30px;
    height:25px;
    position: relative;
    bottom:2px;
}

.comp1 a i{
color:black;
font-size: 2rem;
}


@media (max-width:1630px){
    .comp1 h1{
        width:50%;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
    .comp1 .card h2 {
        font-size: 2rem;
    }
    .comp1 .card p {
        font-size: 1rem;
    }
    /* .comp1 .slick-prev, .comp1 .slick-next{
        top: 30rem !important;
    } */
}


    /* Large Devices (laptops/desktops, up to 1440px) */
@media screen and (max-width: 1440px) {
    .comp1 {
        /* margin: 20px 4%;
        margin-bottom: 60px;
        height:auto;
        height:120vh; */
    }
    .comp1 h1 {
        width: 50%;
        font-size: 2.6rem;
    }

    .comp1 .card {
        width: 85% !important;
        padding: 45px 30px;
        padding-bottom: 125px;
        /* height:82vh; */
    }
    .comp1 .card h2 {
        font-size: 2rem;
    }

    .comp1 .card p {
        font-size: 1.2rem;
    }
}

/* Medium Devices (laptops, up to 1200px) */

@media screen and (max-width: 1250px) {
    /* .comp1 {
        margin-bottom: 60px;
        height:130vh;
    } */
    
    .comp1 h1 {
        width: 60%;
        font-size: 3rem;
    }

    .comp1 .card {
        height: 350px;
        width: 92% !important;
        /* padding: 20px 30px; */
        /* padding-bottom: 44px; */
    }

    .comp1 .card h2 {
        font-size: 1.9rem;
    }

    .comp1 .card p {
        font-size: 1.1rem;
    }

    .slick-prev {
        /* top: 37rem !important; */
        left: 2.5rem !important;
    }

    .slick-next {
        /* top: 37rem !important; */
        left: 13rem !important;
    }
}

@media screen and (max-width: 1080px) {
    /* .comp1 {
        margin-bottom: 60px;
        height:128vh;
    } */
    
    .comp1 h1 {
        width: 70%;
        font-size: 3rem;
    }

    .comp1 .card {
        width: 92% !important;
        padding: 20px 30px;
        padding-bottom: 120px;
    }

    .comp1 .card h2 {
        font-size: 1.9rem;
       
    }

    .comp1 .card p {
        font-size: 1.1rem;
    }

    .slick-prev {
        /* top: 36rem !important; */
        left: 2.5rem !important;
    }

    .slick-next {
        /* top: 36rem !important; */
        left: 10rem !important;
    }
}

/* Tablets and smaller laptops (up to 992px) */
@media screen and (max-width: 992px) {
    .comp1 {
        /* margin: 15px 3%;
        margin-bottom: 60px;
        height:126vh; */
        /* padding-bottom: 60px; */
    }
   
    .comp1 h1 {
        width: 70%;
        font-size: 2.8rem;
        padding: 15px;
    }

    .comp1 .card {
        width: 92% !important;
        padding: 20px 25px;
        padding-bottom: 110px;
        /* height:82vh; */
    }

    .comp1 .card .imgg {
        width: 56px;
        height: 56px;
    }

    .comp1 a {
        padding: 20px 0px;
        font-size: 17px;
    }

    /* Adjusting slider arrows */
    .slick-prev {
        /* top: 37rem !important; */
        left: 2rem !important;
        padding: 30px 45px !important;
    }

    .slick-next {
        /* top: 37rem !important; */
        left: 11.5rem !important;
        padding: 30px 45px !important;
    }

    .slick-prev img, .slick-next img {
        width: 45px !important;
        height: 45px !important;
    }
}

/* Medium tablets (up to 768px) - Switch to single card view */
@media screen and (max-width: 768px) {
    /* .comp1{
        margin-bottom: 80px;
        height:95vh;
    } */
    .comp1 .img{
        justify-self:center;
        width:30px;
        height:30px;
        margin:20px;
        position: relative;
        top: 26px;
      }

    .comp1 h1 {
        width: 90%;
        font-size: 2.5rem;
        text-align: center;
        margin: 0 auto;
    }

    .comp1 .card {
        /* width: 80% !important;
        height:50vh;
        padding: 30px 40px;
        padding-bottom: 50px !important;
        margin-right: 12px;
        margin-left: 12px;
        margin-top:20px; */
        padding-bottom: 50px;
    }

    .comp1 .card h2 {
        font-size: 1.8rem;
    }

    .comp1 .card p {
        font-size: 1.1rem;
    }

    .comp1 a {
        padding: 0;
               font-size: 16px;
        margin: 0px;
        margin-top: 20px;
    }

    /* Adjusting slider arrows */
    .slick-prev {
        /* top: 34rem !important; */
        left: 5rem !important;
        padding: 30px 44px !important;
    }

    .slick-next {
        /* top: 34rem !important; */
        left: 14rem !important;
        padding: 30px 44px !important;
    }

    .slick-prev img, .slick-next img {
        width: 40px !important;
        height: 40px !important;
        top: 8px !important;
    }
}

/* Mobile devices (up to 576px) */
@media screen and (max-width: 576px) {
    .comp1 .slick-arrow{
        display: none !important;
    }
    .comp1 {
        padding-bottom: 70px;
        margin: 0;
        /* margin-bottom: 40px; */
    }
    .comp1 .slick-track{
        display: flex;
        gap:25px;
        margin-left: -5%;
    }

    .comp1 h1 {
        width: 100%;
        font-size: 2rem;
        padding: 10px;
        text-align: center;
    }

    .comp1 .card {
        width: 100% !important;
        padding: 25px;
        padding-bottom: 60px;
        border-radius: 24px;
        margin-right: 8px;
        margin-left: 8px;
        margin-top: 10px;
    }

    .comp1 .card .imgg {
        width: 48px;
        height: 48px;
    }

    .comp1 .card h2 {
        font-size: 2rem;
    }

    .comp1 .card p {
        font-size: 1.4rem;
    }

    .comp1 a {
        padding: 15px 0px;
        font-size: 15px;
        margin-top: 0px;
    }

    .comp1 a img {
        width: 25px;
        height: 20px;
    }

    /* Adjusting slider arrows */
    .slick-prev {
        /* top: 34rem !important; */
        left: 7rem !important;
        padding: 20px 30px !important;
    }

    .slick-next {
        /* top: 34rem !important; */
        left: 17.5rem !important;
        padding: 20px 30px !important;
    }

    .slick-prev img, .slick-next img {
        width: 35px !important;
        height: 35px !important;
        top: 3px !important;
        right: 2.5rem !important;
    }
}

/* Small mobile devices (up to 400px) */
@media screen and (max-width: 400px) {
    .comp1 {
        padding-bottom: 30px;
    }
    .comp1 h1 {
        font-size: 1.8rem;
    }

    .comp1 .card {
       height: fit-content;
        /* padding: 20px 12px;
        padding-bottom: 85px; */
    }

    .comp1 .card .imgg {
        width: 40px;
        height: 40px;
    }

    /* Adjusting slider arrows */
    .slick-prev {
        /* top: 31rem !important; */
        left: 6.5rem !important;
        padding: 18px 30px !important;
    }

    .slick-next {
        /* top: 31rem !important; */
        left: 16rem !important;
        padding: 18px 30px !important;
    }

    .slick-prev img, .slick-next img {
        width: 30px !important;
        height: 30px !important;
        top: 3px !important;
        right: 2.5rem !important;
    }
}






@media(max-width:380px){
     /* Adjusting slider arrows */
     .slick-prev {
        /* top: 32.5rem !important; */
        left: 6.5rem !important;
        padding: 18px 30px !important;
    }

    .slick-next {
        /* top: 32.5rem !important; */
        left: 16rem !important;
        padding: 18px 30px !important;
    }
}