#jobBox.all-jobs{
    padding-top: 100px;
max-width:80%;
width:100%;
margin: 0 auto;
justify-content: center;

@media (max-width:576px) {
    max-width: 90%;
    
}
}


#jobBox.all-jobs .jobBoxR{
    overflow: visible;
    max-height: fit-content;
    max-width:750px
}
#jobBox.all-jobs .jobBoxR h1{
    text-align: center;
    padding-bottom: 20px;
    text-transform: uppercase;
} 